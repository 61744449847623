import { useState, useEffect } from 'react';

function useWindowSize() {
	const [windowSize, setWindowSize] = useState<{
		width: number | undefined;
		height: number | undefined;
	}>({
		width: undefined,
		height: undefined
	});

	let resizeTimer;

	useEffect(() => {
		function handleResize() {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(function () {
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight
				});
			}, 250);
		}

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return windowSize;
}

export default useWindowSize;
