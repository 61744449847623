import React from 'react';
import { FC } from 'react';

const Container: FC = ({ children }) => {
	return (
		<div className="px-5 mx-auto max-w-screen-xl md:px-8 lg:px-10">
			{children}
		</div>
	);
};

export default Container;
