import Container from '@components/ui/container/Container';
import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';
import { FC } from 'react';

type FooterProps = {
	background?: boolean;
};

const Footer: FC<FooterProps> = ({ background = true }) => {
	return (
		<div className={cx('py-8 mt-auto', { 'bg-gray-light': background })}>
			<Container>
				<div className="sm:flex justify-between text-gray-dark">
					<div className="font-bold text-sm mb-5 sm:m-0 text-center sm:text-left">
						<span className="block sm:inline">Copyright 2021</span>
						<span className="hidden sm:inline"> - </span>
						<span className="block sm:inline">
							Iwanejko-Taczkowska Legal & Advisory
						</span>
					</div>
					<div className="space-x-6 text-sm text-center sm:text-right">
						<Link to={'/polityka-prywatnosci'} className="hover:text-blue">
							Polityka prywatności
						</Link>
						<Link to={'/kontakt'} className="hover:text-blue">
							Kontakt
						</Link>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default Footer;
