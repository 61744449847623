import Logo from '@assets/images/iwanejko-taczkowska-legal-and-advisory-logo.svg';
import Container from '@components/ui/container/Container';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import useWindowSize from '@lib/useWindowSize.hook';
import { t } from '@lingui/macro';
import cx from 'classnames';
import { AnimatePresence, motion, useViewportScroll } from 'framer-motion';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { FC } from 'react';

type HeaderProps = {
	location: string;
};
const Header: FC<HeaderProps> = ({ location }) => {
	const currentPage = location;

	const windowSize = useWindowSize();
	const [isMobile, setIsMobile] = useState(
		windowSize.width && windowSize.width < 1024
	);
	const { scrollY } = useViewportScroll();
	const [isFollowing, setIsFollowing] = useState(scrollY.get() > 20);

	useEffect(() => {
		const updateHeader = () =>
			scrollY.get() > 20 ? setIsFollowing(true) : setIsFollowing(false);

		const unsubscribeY = scrollY.onChange(updateHeader);

		return () => {
			unsubscribeY();
		};
	}, []);

	useEffect(() => {
		windowSize.width && setIsMobile(windowSize.width < 1024);
	}, [windowSize.width]);

	const [isNavOpen, setIsNavOpen] = useState(false);

	const data = [
		{
			label: t`Kancelaria`,
			link: '/kancelaria'
		},
		{
			label: t`Specjalizacje`,
			link: '/specjalizacje'
		},
		{
			label: t`Kontakt`,
			link: '/kontakt'
		}
	];

	return (
		<header
			className={cx(
				'overflow-hidden fixed top-0 left-0 z-50 w-full transition duration-500',
				{
					'bg-white shadow-lg': isFollowing
				}
			)}
		>
			<Container>
				<div
					className={cx(
						'flex justify-between items-center transition-height duration-300',
						isFollowing ? 'h-14 sm:h-16 lg:h-20' : 'h-20'
					)}
				>
					<Link to={'/'} className="block">
						<Logo
							className={cx(
								'w-auto h-8 sm:h-10 lg:h-auto fill-current transition duration-500',
								isFollowing ? 'text-black' : 'text-white'
							)}
						/>
					</Link>

					<div className="hidden lg:block">
						{data.map((item) => (
							<HeaderNavButton
								key={item.label}
								label={item.label}
								link={item.link}
								isActive={currentPage.includes(item.link)}
								isFollowing={isFollowing}
							/>
						))}
					</div>
					<div className="block lg:hidden">
						<AnimatePresence>
							{!isNavOpen
								? isMobile && (
										<motion.div
											initial={{ x: 100 }}
											animate={{ x: 0 }}
											exit={{ x: 100 }}
										>
											<button
												onClick={() => setIsNavOpen(!isNavOpen)}
												className={cx(
													'p-2  transition hover:text-blue focus:outline-none',
													isFollowing ? 'text-black' : 'text-white'
												)}
											>
												<MenuIcon className="w-6 h-6" />
											</button>
										</motion.div>
								  )
								: null}
						</AnimatePresence>
					</div>

					<div className="block overflow-hidden fixed top-0 left-0 w-full lg:hidden">
						<AnimatePresence>
							{isNavOpen && (
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.5 }}
									className="grid relative justify-items-center py-32 px-16 w-full h-screen bg-black bg-opacity-90 lg:hidden"
								>
									{data.map((item) => (
										<HeaderNavButton
											key={item.label}
											label={item.label}
											link={item.link}
											isActive={currentPage.includes(item.link)}
										/>
									))}

									<motion.button
										initial={{ x: 100 }}
										animate={{ x: 0 }}
										exit={{ x: 100 }}
										onClick={() => setIsNavOpen(!isNavOpen)}
										className="absolute top-6 right-6 p-2 transition text-gray-dark hover:text-white focus:outline-none lg:hidden"
									>
										<XIcon className="w-8 h-8" />
									</motion.button>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</div>
			</Container>
		</header>
	);
};

export default Header;

type HeaderNavButtonProps = {
	label: string;
	link: string;
	isActive?: boolean;
	isFollowing?: boolean;
};
const HeaderNavButton: FC<HeaderNavButtonProps> = ({
	label,
	link,
	isActive = false,
	isFollowing
}) => {
	return (
		<Link
			to={link}
			className={cx(
				'inline-flex items-center justify-center h-full lg:h-20 overflow-hidden md:overflow-auto relative px-5 w-full max-w-sm text-2xl font-bold transition-padding transition duration-300 lg:w-auto group sm:text-3xl md:text-4xl lg:text-base focus:outline-none',
				isFollowing
					? isActive
						? 'text-white'
						: 'text-gray-dark'
					: isActive
					? 'text-white'
					: 'text-white lg:text-white'
			)}
		>
			<span className="relative z-10">{label}</span>
			<span
				className={cx(
					'block absolute top-0 left-0 w-full h-full transition transform duration-300 focus:outline-none',
					isActive
						? 'bg-blue group-hover:bg-blue-shade'
						: '-translate-y-full group-hover:translate-y-0',
					isFollowing
						? isActive
							? 'group-hover:bg-blue-shade'
							: 'group-hover:bg-beige'
						: isActive
						? 'group-hover:bg-blue-shade'
						: 'group-hover:bg-brown-shade'
				)}
			/>
		</Link>
	);
};
