import Container from '@components/ui/container/Container';
import cx from 'classnames';
import React from 'react';
import { FC } from 'react';

type TitleBarProps = {
	fullHeight?: boolean;
};

const TitleBar: FC<TitleBarProps> = ({ fullHeight, children }) => {
	return (
		<div
			className={cx(
				'relative bg-brown relative pt-20 pb-14 md:pt-24 lg:pt-28',
				{
					'h-96 md:h-screen md:min-h-560 md:max-h-700': fullHeight
				}
			)}
		>
			<Container>{children}</Container>
		</div>
	);
};

export default TitleBar;
